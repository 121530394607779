import './NavBar.css';
import { useTranslation } from 'react-i18next';
import NavLink from './NavLink';
import DarkModeToggle from '../darkmodetoggle/DarkModeToggle';
import LanguageSelector from '../languageselector/LanguageSelector';

const NavBar = ({ current, isDark, setIsDark, onLanguageChange }) => {
  const { t } = useTranslation(['sections']);

  const sections = Object.entries(t('sections', { returnObjects: true })).map(
    (section) => {
      const highlight = section[0] === current;
      return (
        <NavLink
          highlight={highlight}
          link={section[0]}
          title={section[1].toLowerCase()}
        />
      );
    },
  );

  return (
    <div className="nav-bar">
      <div className="nav-bar-settings">
        <DarkModeToggle isDark={isDark} setIsDark={setIsDark} />
        <LanguageSelector onLanguageChange={onLanguageChange} />
      </div>
      <div className="nav-bar-links">
        {sections}
        <a
          className="cv-link"
          href="./Chris Topping - CV.pdf"
          target="_blank rel='noopener noreferrer'"
        >
          CV
        </a>
      </div>
    </div>
  );
};

export default NavBar;
