import ContactType from './ContactType';
import './Contact.css';

import github from './images/github.png';
import linkedin from './images/linkedin.png';
import website from './images/web.png';
import email from './images/email.png';
import phone from './images/phone.png';

const Contact = ({ type, link, value }) => {
  const logo = getLogo(type);

  return (
    <a className={'contact-link'} href={link} rel="noreferrer" target="_blank">
      <img alt={type} className="contact-logo" src={logo} />
      <p className="contact-info">{value}</p>
    </a>
  );
};

const getLogo = (type) => {
  switch (type.toLowerCase()) {
    case ContactType.EMAIL:
      return email;
    case ContactType.WEBSITE:
      return website;
    case ContactType.MOBILE:
      return phone;
    case ContactType.LINKED_IN:
      return linkedin;
    case ContactType.GITHUB:
      return github;
    default:
      return;
  }
};

export default Contact;
