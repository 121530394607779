import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import languagesData from './locales/en/languages.json';

const languages = languagesData.languages.map((language) => language._iso2);

const resourcesArray = languages.map((language) => {
  return {
    [language]: {
      about: require(`./locales/${language}/about.json`),
      contact: require(`./locales/${language}/contact.json`),
      education: require(`./locales/${language}/education.json`),
      experience: require(`./locales/${language}/experience.json`),
      languages: require(`./locales/${language}/languages.json`),
      sections: require(`./locales/${language}/sections.json`),
      skills: require(`./locales/${language}/skills.json`),
    },
  };
});

const resources = Object.assign({}, ...resourcesArray);

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    supportedLngs: languages,
    resources,
    ns: [
      'about',
      'education',
      'experience',
      'languages',
      'sections',
      'skills',
      'contact',
    ],
    defaultNS: 'translations',
    react: { useSuspense: true },
  });

export default i18n;
