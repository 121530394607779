import React from 'react';

const sections = {
  about: {
    ref: React.createRef(),
    current: 'about',
    next: 'skills',
    direction: 'down',
  },
  skills: {
    ref: React.createRef(),
    current: 'skills',
    next: 'experience',
    direction: 'down',
  },
  experience: {
    ref: React.createRef(),
    current: 'experience',
    next: 'contact',
    direction: 'down',
  },
  contact: {
    ref: React.createRef(),
    current: 'contact',
    next: 'about',
    direction: 'up',
  },
};

export const getSections = () => {
  return sections;
};

export const updateCurrentSection = (currentSection, callback, lastRef) => {
  const current = Object.keys(sections)
    .map((key) => sections[key])
    .filter((ref) => !!ref.ref.current)
    .map((ref) => {
      let bounding = ref?.ref?.current?.getBoundingClientRect();
      return { ...ref, bounding };
    })
    .filter((ref) => !!ref.bounding)
    .filter((ref) => ref.bounding.y <= 100)
    .sort((first, second) => second?.bounding?.y - first?.bounding?.y)[0];

  if (window.innerHeight >= lastRef.current.getBoundingClientRect().y) {
    callback(sections.contact);
  } else if (current && currentSection.next !== current.next) {
    callback(current);
  }
};
