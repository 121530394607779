import ExperienceDisplayItem from './ExperienceDisplayItem';

const ExperienceDisplay = ({ selected }) => {
  return (
    <div className="experience-display">
      <ExperienceDisplayItem experience={selected} isSelected />
    </div>
  );
};

export default ExperienceDisplay;
