const SkillsToggleItem = ({ name, onClick, selected }) => {
  const className =
    selected === name ? 'skills-toggle-item-selected' : 'skills-toggle-item';

  return (
    <div className={className} onClick={() => onClick(name)}>
      <p>{name}</p>
    </div>
  );
};

export default SkillsToggleItem;
