import React, { useState } from 'react';
import './Experience.css';
import { useTranslation } from 'react-i18next';
import ExperienceToggle from './ExperienceToggle';
import ExperienceDisplay from './ExperienceDisplay';
import i18next from 'i18next';

const Experience = () => {
  const { t } = useTranslation(['experience', 'sections']);

  const [experiences, setExperiences] = useState(
    t('experience', { returnObjects: true }),
  );

  const [selected, setSelected] = useState(experiences[0]);

  i18next.on('languageChanged', () => {
    setExperiences(t('experience', { returnObjects: true }));
    setSelected(t('experience', { returnObjects: true })[0]);
  });

  const selectItem = (experience) => {
    setSelected(experience);
  };

  return (
    <div className="section-container Experience-container ">
      <p className="section-title">{t('sections:sections.experience')}</p>
      <div className="Experience-sub-container">
        <ExperienceToggle
          experiences={experiences}
          selectItem={selectItem}
          selected={selected}
        />
        <ExperienceDisplay selected={selected} />
      </div>
    </div>
  );
};

export default Experience;
