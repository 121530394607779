import React from 'react';
import './About.css';
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';

const About = () => {
  const { t } = useTranslation(['about', 'sections']);

  const renderedSummary = t('about.summary', { returnObjects: true }).map(
    (summaryItem) => {
      return <p className="summary">{parse(summaryItem)}</p>;
    },
  );

  return (
    <div className="section-container about-container">
      <div className="about-body-container">
        <p className="supertitle">{t('about.pre-name')}</p>
        <p className="name">{t('about._name')}</p>
        <p className="supertitle-2">{t('about.pre-title')}</p>
        <p className="subtitle">{t('about.title')}</p>
        <p className="location">
          {t('about.pre-location')} {t('about.location')}
        </p>
        {renderedSummary}
      </div>
    </div>
  );
};

export default About;
