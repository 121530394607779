import React, { useEffect, useState } from 'react';
import './App.css';
import './components/item.css';
import './components/section.css';
import About from './components/about/About';
import Experience from './components/experience/Experience';
import Education from './components/education/Education';

import { getContacts } from './services/data-service';
import Skills from './components/skills/Skills';
import NavBar from './components/navbar/NavBar';
import ContactForm from './components/contactform/ContactForm';
import Fab from './components/fab/Fab';
import Contacts from './components/contacts/Contacts';
import { getSections, updateCurrentSection } from './services/sections-service';
import i18n from 'i18next';

function App() {
  const sections = getSections();

  const year = new Date().getFullYear();
  const copyrightDateString = year > 2023 ? `2023 - ${year}` : '2023';

  const footerRef = React.createRef();

  const [currentSection, setCurrentSection] = useState(sections.about);

  // eslint-disable-next-line
  const [, setTrigger] = useState();

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    updateCurrentSection(currentSection, setCurrentSection, footerRef);
  }, [footerRef, currentSection]);

  const handleScroll = () => {
    setTrigger(new Date());
  };

  const onLanguageChange = (language) => {
    i18n.changeLanguage(language._iso2);
  };

  const [isDark, setIsDark] = useState(false);

  return (
    <div className="App">
      <section id="about" ref={sections.about.ref}>
        <Fab
          direction={currentSection.direction}
          next={currentSection.next}
          onClick={handleScroll}
        />
        <NavBar
          current={currentSection.current}
          isDark={isDark}
          setIsDark={setIsDark}
          onLanguageChange={onLanguageChange}
        />
        <div className="App">
          <div className="body-container">
            <About />
            <section id="skills" ref={sections.skills.ref}>
              <Skills isDark={isDark} />
            </section>
            <section id="experience" ref={sections.experience.ref}>
              <Experience />
            </section>
            {/*<section id="education" ref={sections.education.ref}>*/}
            {/*  <Education />*/}
            {/*</section>*/}
            <section id="contact" ref={sections.contact.ref}>
              <ContactForm />
              <Contacts contacts={getContacts()} />
              <p className="footer" ref={footerRef}>
                Copyright © {copyrightDateString} Chris Topping. Icons by{' '}
                <a href="https://icons8.com/">Icons8</a>.
              </p>
            </section>
          </div>
        </div>
      </section>
    </div>
  );
}

export default App;
