import { Link } from 'react-scroll';
import './NavBar.css';
import '../../App.css';

const NavLink = ({ title, link, highlight }) => {
  const linkClass = highlight ? 'highlighted' : '';
  return (
    <div className={linkClass}>
      <Link rel="nofollow" duration={300} smooth spy to={link}>
        {title}
      </Link>
    </div>
  );
};

export default NavLink;
