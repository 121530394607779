import parse from 'html-react-parser';

const ExperienceDisplayItem = ({ experience, isSelected }) => {
  const buildSection = (section) => {
    return (
      <div className="experience-display-item-section">
        <p className="experience-display-item-section-name">{section.name}</p>
        <ul>
          {section.bullets
            .filter((bullet) => !bullet._hide)
            .map((bullet) => {
              return <li className="bullet">{parse(bullet.text)}</li>;
            })}
        </ul>
      </div>
    );
  };

  const buildItem = (item) => {
    return (
      <div>
        <p className="role">{item.role}</p>
        <p>
          {experience.startDate} - {experience.endDate}
        </p>
        {item.sections.map(buildSection)}
      </div>
    );
  };

  return (
    <div
      className="experience-display-item"
      style={{ display: isSelected ? 'block' : 'none' }}
    >
      <p>{experience.items.map(buildItem)}</p>
      <a href={experience._companyWebsite} target="_blank">
        {experience._companyWebsite}
      </a>
    </div>
  );
};

export default ExperienceDisplayItem;
