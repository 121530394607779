import React, { useEffect, useState } from 'react';

const Skill = ({ skill, selected, isDark }) => {
  const { _iconName, _iconDirectory, _name, _link } = skill;

  const isSelected = () => {
    return skill?._categories.includes(selected?.toLowerCase());
  };

  const [icon, setIcon] = useState(
    `${process.env.PUBLIC_URL}/technologies/${_iconDirectory}/colour/${_iconName}`,
  );

  const toColour = () => {
    setIcon(
      `${process.env.PUBLIC_URL}/technologies/${_iconDirectory}/colour/${_iconName}`,
    );
  };

  const toGreyscale = () => {
    if (!isSelected()) {
      const greyscale = isDark ? 'white' : 'black';
      setIcon(
        `${process.env.PUBLIC_URL}/technologies/${_iconDirectory}/${greyscale}/${_iconName}`,
      );
    }
  };

  useEffect(() => {
    if (isSelected()) {
      toColour();
    } else {
      toGreyscale();
    }
  }, [selected, isDark]);

  return (
    <a href={_link} target="_blank">
      <div className="Skill" onMouseOut={toGreyscale} onMouseOver={toColour}>
        <img alt="" className="Skill-icon" src={icon} />
        <p className="Skill-title">{_name}</p>
      </div>
    </a>
  );
};

export default Skill;
