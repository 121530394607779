import React, { useRef, useState } from 'react';
import i18next from 'i18next';
import './LanguageSelector.css';

import languagesData from '../../i18n/locales/en/languages.json';
import useClickOutside from '../../hooks/onClickOutside';
import i18n from 'i18next';

const LanguageSelector = ({ onLanguageChange }) => {
  const [displaySelector, setDisplaySelector] = useState(false);

  const english = languagesData.languages?.find(
    (language) => language._iso2 === 'en',
  );

  const getLanguageByISO2 = (iso2) => {
    return (
      languagesData.languages?.find((language) => iso2 === language._iso2) ||
      english
    );
  };

  const [selected, setSelected] = useState(
    getLanguageByISO2(i18next.language) ||
      getLanguageByISO2(window.localStorage.i18nextLng) ||
      english,
  );

  const containerRef = useRef('language-selector');
  useClickOutside(containerRef, () => {
    if (displaySelector) {
      setDisplaySelector(false);
    }
  });

  const toggleDisplaySelector = () => {
    setDisplaySelector(!displaySelector);
  };

  const handleChange = (language) => {
    setSelected(language);
    setDisplaySelector(false);
    onLanguageChange(language);
  };

  const languageElements = languagesData.languages
    .filter((language) => language !== selected)
    .map((language) => {
      return (
        <p className="language" onClick={() => handleChange(language)}>
          {language._name}
        </p>
      );
    });

  return (
    <div className="selector-container" ref={containerRef}>
      <p className="language" onClick={toggleDisplaySelector}>
        {selected._name}
      </p>
      <div className="selector-dropdown-container" hidden={!displaySelector}>
        {languageElements}
      </div>
    </div>
  );
};

export default LanguageSelector;
