import contacts from '../data/contacts.json';
import axios from 'axios';

export const getContacts = () => {
  return contacts;
};

export const getVariable = async (name) => {
  console.log(`making request to GET /variables/${name}`);
  return (await axios.get(`/variables/${name}`)).data;
};
