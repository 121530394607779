import './Fab.css';
import { Link } from 'react-scroll';
import up from './images/up.png';
import down from './images/down.png';

const Fab = ({ next, direction, onClick }) => {
  const arrow = direction === 'down' ? down : up;

  return (
    <Link duration={600} smooth spy to={next}>
      <div className="fab-container" onClick={onClick}>
        <img alt="" height="20px" src={arrow} width="20px" />
      </div>
    </Link>
  );
};

export default Fab;
