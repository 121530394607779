import ExperienceToggleItem from './ExperienceToggleItem';

const ExperienceToggle = ({ experiences, selected, selectItem }) => {
  const experienceButtons = experiences
    .filter((experience) => experience._display)
    .map((experience) => {
      let isSelected = experience._companyName === selected._companyName;
      return (
        <ExperienceToggleItem
          experience={experience}
          selectItem={selectItem}
          selected={isSelected}
        />
      );
    });

  return <div className="experience-toggle">{experienceButtons}</div>;
};

export default ExperienceToggle;
