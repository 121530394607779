import React from 'react';
import { Button, Form, Input, TextArea } from 'semantic-ui-react';
import emailjs from 'emailjs-com';
import './ContactForm.css';
import { useTranslation } from 'react-i18next';
import { getVariable } from '../../services/data-service';

const ContactForm = () => {
  const [serviceId, setServiceId] = React.useState(
    process.env.EMAILJS_SERVICE_ID,
  );
  const [templateId, setTemplateId] = React.useState(
    process.env.EMAILJS_TEMPLATE_ID,
  );
  const [publicKey, setPublicKey] = React.useState(
    process.env.EMAILJS_PUBLIC_KEY,
  );

  const fetchData = async () => {
    await getVariable('EMAILJS_SERVICE_ID').then((result) => {
      setServiceId(result);
    });
    await getVariable('EMAILJS_TEMPLATE_ID').then((result) => {
      setTemplateId(result);
    });
    await getVariable('EMAILJS_PUBLIC_KEY').then((result) => {
      setPublicKey(result);
    });
  };

  React.useEffect(() => {
    fetchData().catch((error) => console.log(error));
  }, []);

  const { t } = useTranslation(['contact', 'sections']);

  const handleOnSubmit = (e) => {
    console.dir(e);
    e.preventDefault();
    emailjs.sendForm(serviceId, templateId, e.target, publicKey).then(
      (result) => {},
      (error) => {
        console.log(error.text);
      },
    );
    e.target.reset();
  };

  return (
    <div className="section-container ContactForm">
      <p className="section-title">{t('title')}</p>
      <Form onSubmit={handleOnSubmit}>
        <Form.Field
          className="ui contact-form-input-field"
          control={Input}
          icon="user circle"
          iconPosition="left"
          id="form-input-control-last-name"
          label={t('name')}
          name="from_name"
          placeholder="..."
          required
        />
        <Form.Field
          className="contact-form-input-field"
          control={Input}
          icon="mail"
          iconPosition="left"
          id="form-input-control-email"
          label={t('email')}
          name="from_email"
          placeholder="..."
          required
        />
        <Form.Field
          className="contact-form-text-field"
          control={TextArea}
          id="form-textarea-control-opinion"
          label={t('message')}
          name="message"
          placeholder="..."
          required
        />
        <Button className="contact-form-submit" color="green" type="submit">
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default ContactForm;
