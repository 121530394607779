import Contact from './Contact';
import React from 'react';

const Contacts = ({ contacts }) => {
  const renderedContacts = contacts.map(({ type, link, value }) => {
    return <Contact link={link} type={type} value={value} />;
  });

  return <div className="contact-container">{renderedContacts}</div>;
};

export default Contacts;
