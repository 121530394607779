import React, { useState } from 'react';
import './Skills.css';
import { useTranslation } from 'react-i18next';
import Skill from './Skill';
import SkillsToggle from './SkillsToggle';
import parse from 'html-react-parser';

const Skills = ({ isDark }) => {
  const [selected, setSelected] = useState();

  const { t } = useTranslation(['skills', 'sections']);

  const skills = t('skills', { returnObjects: true })
    .sort((first, second) => first._name.localeCompare(second._name))
    .map((skill) => {
      return <Skill isDark={isDark} selected={selected} skill={skill} />;
    });

  return (
    <div className="Skills section-container">
      <p className="section-title">{t('sections:sections.skills')}</p>
      <p className="skills-description">{parse(t('description'))}</p>
      <SkillsToggle selected={selected} setSelected={setSelected} />
      <div className="Skills-container">{skills}</div>
    </div>
  );
};

export default Skills;
