import SkillsToggleItem from './SkillsToggleItem';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const SkillsToggle = ({ selected, setSelected }) => {
  const { t } = useTranslation(['skills']);

  const clickItem = (name) => {
    if (selected && selected === name) {
      setSelected(null);
    } else {
      setSelected(name);
    }
  };

  return (
    <div className="skills-toggle">
      <SkillsToggleItem
        name={t('categories.backend')}
        onClick={clickItem}
        selected={selected}
      />
      <SkillsToggleItem
        name={t('categories.frontend')}
        onClick={clickItem}
        selected={selected}
      />
      <SkillsToggleItem
        name={t('categories.data')}
        onClick={clickItem}
        selected={selected}
      />
      <SkillsToggleItem
        name={t('categories.infrastructure')}
        onClick={clickItem}
        selected={selected}
      />
      <SkillsToggleItem
        name={t('categories.language')}
        onClick={clickItem}
        selected={selected}
      />
      <SkillsToggleItem
        name={t('categories.framework')}
        onClick={clickItem}
        selected={selected}
      />
    </div>
  );
};

export default SkillsToggle;
