const ExperienceToggleItem = ({ experience, selected, selectItem }) => {
  const className = selected
    ? 'experience-toggle-item-selected'
    : 'experience-toggle-item';

  const handleClick = () => {
    selectItem(experience);
  };

  return (
    <div className={className} onClick={handleClick}>
      <p className="experience-toggle-item-label">{experience._companyName}</p>
    </div>
  );
};
export default ExperienceToggleItem;
