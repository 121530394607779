import { useMediaQuery } from 'react-responsive';
import React, { useEffect } from 'react';
import { DarkModeSwitch } from 'react-toggle-dark-mode';

const DarkModeToggle = ({ isDark, setIsDark }) => {
  const systemPrefersDark = useMediaQuery(
    {
      query: '(prefers-color-scheme: dark)',
    },
    undefined,
    (prefersDark) => {
      setIsDark(prefersDark);
    },
  );

  useEffect(() => {
    if (isDark) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDark]);

  return (
    <DarkModeSwitch
      checked={isDark}
      onChange={() => setIsDark(!isDark)}
      size={40}
    />
  );
};

export default DarkModeToggle;
